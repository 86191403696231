@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  min-height: 100%;
}

body {
  background: #000000 url(/static/media/background.83252e0a.jpg) no-repeat;
  background-size: cover;
  -webkit-font-smoothing: antialiased !important;
}

body,
input,
button {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.container {
  margin: 80px auto;
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  width: 100%;
  background: #fff;
  margin-top: 50px;
  border-radius: 4px;
  padding: 30px;
}

.content > p {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 30px;
}

.content form {
  display: flex;
  flex-direction: column;
}

.content form label {
  font-size: 14px;
  color: #444;
  font-weight: bold;
  margin-bottom: 8px;
}

.content form label span {
  font-weight: normal;
  color: #999;
  font-size: 12px;
}

.content form input {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 2px;
  height: 45px;
  padding: 0 15px;
  font-size: 16px;
}

.content button.btn {
  border: 0;
  border-radius: 2px;
  width: 100%;
  height: 42px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  background: #f05a5b;
  color: #fff;
  cursor: pointer;
}

.content button.btn:hover {
  background: #e14f50;
}

ul.spot-list {
  width: 100%;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 30px;
}

ul.spot-list li {
  display: flex;
  flex-direction: column;
}

ul.spot-list li header {
  width: 100%;
  height: 120px;
  background-size: cover;
  border-radius: 4px;
}

ul.spot-list li strong {
  margin-top: 10px;
  font-size: 24px;
  color: #444;
}

ul.spot-list li span {
  font-size: 15px;
  color: #999;
}

ul.notifications {
  list-style: none;
  margin-bottom: 15px;
}

ul.notifications li {
  font-size: 16px;
  line-height: 24px;
}

ul.notifications li button {
  margin-right: 10px;
  border: 0;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
}

ul.notifications li button.accept {
  color: #84c870;
  background: #fff;
}

ul.notifications li button.reject {
  color: #e55e5e;
  background: #fff;
}

label#thumbnail {
  margin-bottom: 20px;
  border: 1px dashed #ddd;
  background-size: cover;
  cursor: pointer;
  height: 160px;

  display: flex;
  justify-content: center;
  align-items: center;
}

label#thumbnail input {
  display: none;
}

label#thumbnail.has-thumbnail {
  border: 0;
}

label#thumbnail.has-thumbnail img {
  display: none;
}

