label#thumbnail {
  margin-bottom: 20px;
  border: 1px dashed #ddd;
  background-size: cover;
  cursor: pointer;
  height: 160px;

  display: flex;
  justify-content: center;
  align-items: center;
}

label#thumbnail input {
  display: none;
}

label#thumbnail.has-thumbnail {
  border: 0;
}

label#thumbnail.has-thumbnail img {
  display: none;
}
